import {Injectable} from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GLOBAL} from './global';

@Injectable()
export class NotificacionService{
    public url:string;
    


    constructor( public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    last_lista(id): Observable<any>{
        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
        headers.append("Accept", 'application/json');
        console.log(this.url + "mensajes/" + id);
        return this._http.get(this.url + "mensajes/" + id, {headers: headers});
    }
    detalle_Notificacion(id,userid): Observable<any>{
       
        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
        headers.append("Accept", 'application/json');
        return this._http.get(this.url + "detalleNotificacion/"+id, {headers: headers});
    }

    sendInformation(notificacionDetalle){
        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
        headers.append("Accept", 'application/json');
        
        return this._http.post(this.url + "sendInformation",notificacionDetalle, {headers: headers});
    }
    compartir(notificacionCompartir){
        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
        headers.append("Accept", 'application/json');
        
        return this._http.post(this.url + "compartir",notificacionCompartir, {headers: headers});
    }
    
  
}

