import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet',
  templateUrl: './internet.component.html',
  styleUrls: ['./internet.component.scss'],
})
export class InternetComponent implements OnInit {
  public onlineOffline: boolean = navigator.onLine;

  constructor() { }

  ngOnInit() {
    setInterval(() => {         
      //replaced function() by ()=>
      this.onlineOffline = navigator.onLine;
      // just testing if it is working
    }, 1000);
  }

}
