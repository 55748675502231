import {Injectable} from '@angular/core';
import {HttpClient,HttpHeaders,JsonpClientBackend} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GLOBAL} from './global';

@Injectable()
export class EquipoService{
    public euipos:string;
    public url:string;

    constructor( public _http: HttpClient){
        this.url = GLOBAL.urlEcobasa;

    }

    getEquipos(): Observable<any>{
       
        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
        headers.append("Accept", 'application/json');
        
        return this._http.get(this.url +"getequipos", {headers: headers});
    }


  
}

